var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentRole === 4 || _vm.currentRole === 5)?_c('div',{staticClass:"book-inventory mt-5"},[_c('h1',{staticClass:"heading"},[_vm._v("Inventory Item Statement")]),_c('div',{staticClass:"d-flex mt-4 ml-0",staticStyle:{"gap":"10px"}},[_c('Autocomplete',{attrs:{"styleBorder":'out-lined',"placeholder":"Select...","keyFilter":_vm.header.value,"selectedInit":_vm.filters['location'],"listItem":_vm.header.items,"announLabel":_vm.header.announLabel},on:{"setFilter":_vm.setFilter}}),_c('v-select',{staticClass:"select-item",attrs:{"items":_vm.listMonth,"dense":"","label":"Month","outlined":""},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}}),_c('v-select',{staticClass:"select-item",attrs:{"items":_vm.listYear,"dense":"","label":"Year","outlined":""},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.SearchData}},[_vm._v("Search")])],1),(_vm.isLoading)?[_c('div',{staticClass:"pa-6"},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1)]:_vm._e(),(!_vm.isLoading)?_c('v-data-table',{staticClass:"elevation-0 mb-5 bookTable",attrs:{"headers":_vm.headers,"items":_vm.filtered,"items-per-page":1000000,"hide-default-header":"","hide-default-footer":"","fixed-header":"","height":"79vh","id":"bookTable"},scopedSlots:_vm._u([{key:"header",fn:function(ref){return [_c('thead',{staticClass:"v-data-table-header",staticStyle:{"border-radius":"20px","text-align":"center"}},[_c('tr',_vm._l((_vm.headers),function(header,index){return _c('th',{key:index,style:({
              background: '#0b2585',
              color: '#fff',
              textAlign: 'center',
              width: ("" + (header.width)),
              maxWidth: ("" + (header.width)),
            }),domProps:{"innerHTML":_vm._s(header.text)}})}),0)]),_c('tr',{staticClass:"fixed-book-filter"},_vm._l((_vm.headers),function(header){return _c('th',{key:header.text},[(_vm.autoFilters.hasOwnProperty(header.value))?_c('AutoFilter',{attrs:{"styleBorder":'border-bottom',"keyFilter":header.value,"selectedInit":_vm.autoFilters[header.value],"listItem":_vm.groupColumnValueList(header.value)},on:{"setFilter":_vm.setAutoFilter}}):_vm._e()],1)}),0)]}},{key:"body",fn:function(ref){
            var items = ref.items;
return [(items.length === 0)?_c('tbody',{staticStyle:{"text-align":"center"}},[_c('div',{staticClass:"pt-3",staticStyle:{"position":"absolute","left":"50%","transform":"translate(-50%, 0)"}},[_vm._v(" No data available ")])]):_vm._e(),_vm._l((_vm.filteredTotal),function(item,index){return _c('tbody',{key:index},[_c('tr',{staticClass:"row-overall"},[_c('td',{staticStyle:{"text-align":"left !important"},attrs:{"colspan":"4"}},[_c('v-icon',{staticClass:"mr-5",on:{"click":function($event){return _vm.changeHiddenSup(item.locationID)}}},[_vm._v(_vm._s(_vm.listHidden.includes(item.locationID) ? 'mdi-chevron-up' : 'mdi-chevron-down'))]),_c('p',[_vm._v("Center:")]),_vm._v(" "+_vm._s(item.location)+" ")],1),_c('td',[_c('p',[_vm._v(" "+_vm._s(item.overallEndQty)+" ")])])]),_vm._l((item.purchaseOrd),function(item2,index){return _c('tr',{key:index,staticClass:"row-sale"},[(_vm.listHidden.includes(item2.locationID) === true)?_c('td',[_vm._v(" "+_vm._s(index + 1)+" ")]):_vm._e(),(_vm.listHidden.includes(item2.locationID) === true)?_c('td',[_vm._v(" "+_vm._s(item2.productCode)+" ")]):_vm._e(),(_vm.listHidden.includes(item2.locationID) === true)?_c('td',[_vm._v(" "+_vm._s(item2.description)+" ")]):_vm._e(),(_vm.listHidden.includes(item2.locationID) === true)?_c('td',[_vm._v(" "+_vm._s(item2.unit)+" ")]):_vm._e(),(_vm.listHidden.includes(item2.locationID) === true)?_c('td',[_vm._v(" "+_vm._s(item2.endQty)+" ")]):_vm._e()])})],2)}),(items.length > 0)?_c('tfoot',[_c('tr',{staticClass:"bookTableFoot"},[_c('td',{staticClass:"text-h6",staticStyle:{"text-align":"left !important"},attrs:{"colspan":"4"}},[_vm._v("Grand Total")]),_c('td',[_vm._v(" "+_vm._s(_vm.filteredPayment.overallEndQty.toLocaleString())+" ")])])]):_vm._e()]}}],null,false,3852558188)}):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }